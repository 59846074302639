@use "breakpoints.scss";

.drawerBox {
    position: fixed;
    left: 0;
    top: 300px;
    background-color: var(--gray250);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid var(--gray400);
    border-right: 1px solid var(--gray400);
    border-bottom: 1px solid var(--gray400);


}

.drawerDummy {
    display: flex;
    width: 6px;
    height: 96px;
    padding: 2px;
    align-items: center;
    font-size: 50%;

    &.drawerOpen {
        display: none;
    }
}


.container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;

    hr {
        border: none;
        border-left: 1px solid var(--brandLight4);
        height: 22px;
        width: 1px;
    }
}

.drawerContainer {
    display: none;
    width: 40px;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    color: var(--gray800);
    z-index: 999;

    hr {
        width: 22px;
        color: var(--gray400);
    }



    &.drawerOpen {
        display: flex;
    }
}

.up {
    color: #46a78a;
    transform: rotate(-90deg);
}

.minor-up {
    color: #4ab091;
    transform: rotate(-45deg);
}

.down {
    color: #bb9a3f;
    transform: rotate(90deg);
}

.minor-down {
    color: #c0a046;
    transform: rotate(45deg);
}

/* Applies to large screens (+992px) */
@media screen and (breakpoints.$mdUp) {
    .drawerContainer {
        display: flex;
    }

    .drawerDummy {
        display: none;
    }
}