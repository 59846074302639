.list {
    padding: 0;

    border: 1px solid var(--gray250);
    border-bottom: none;
    max-width: 400px;
}

.listItem {
    list-style-type: none;
    border-bottom: 1px solid var(--gray250);
    padding: 10px 14px;
    cursor: pointer;

    &:hover {
        background: var(--brandLight11);
    }
}