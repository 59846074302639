.container {
  padding: 24px;
  max-width: 800px;
  margin: 0 auto;
}

.formGroup {
  margin-bottom: 16px;
}

.status {
  margin-top: 16px;
  padding: 16px;
  border-radius: 4px;

  &.error {
    background-color: #fee2e2;
  }

  &.success {
    background-color: #dcfce7;
  }
}