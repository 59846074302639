.listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px 0 40px 0;



    .sortKnob {
        opacity: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-self: flex-end;
        flex-direction: column;
        gap: 6px;
    }

    .dragHandle {
        cursor: grab;
        padding: 8px;

        &:active {
            cursor: grabbing;
        }
    }

    &.compact {

        padding: 0;

        .sortKnob {
            flex-direction: row;
        }

        margin: 2px 0 2px 0;
    }

    &:hover .sortKnob {

        opacity: 1;

    }
}