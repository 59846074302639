@use 'breakpoints.scss';
@use 'various.scss';

.outerHeader {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 10;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  transition: all .2s linear;

}

.header {

  width: 100%;
  box-sizing: border-box;
  display: flex;
  background: var(--mainBg);

  &.sticky {
    .headerLinks {
      box-shadow: 15px 0px 15px -6px rgba(90, 115, 163, 0.2);

      &.rounded {
        box-shadow: 3px 3px 12px rgba(90, 115, 163, 0.4);
      }
    }
  }

  html[data-theme="dark"] {
    &.sticky {
      .headerLinks {
        box-shadow: none;

        &.rounded {
          box-shadow: none
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  .headerLinks {

    background: var(--mainBrand);

    a {
      font-size: 14px;
    }

    a:focus {
      color: white;
      outline: none;
    }

    li {
      margin-right: 0.625em;
    }

    .headerLinksInner {
      display: grid;
      grid-template-columns: 10% 54% 36%;
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      align-items: center;
      min-height: 58px;
      margin-left: 12px;
      margin-right: 12px;

      .authAndMenu {
        display: flex;
        align-items: center;

        span {
          margin-left: 0.55em;
        }


        a {
          color: var(--brandLight8);
          font-weight: 500;
          font-size: 0.9em;
        }

        a:hover {
          color: white;
        }
      }

      .globalSiteNav {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;

        a,
        span {
          margin-left: 0.35em;
          color: var(--brandLight8);
          font-size: 0.9em;
        }

        a {
          display: flex;
          align-items: center;
          background: transparent;
          border: none;
          font-weight: 500;
        }

        // a.logoLink {
        //   margin-left: 0;
        //   padding: 0;
        //   color: var(--brandLight8);
        // }

        a:hover {
          color: white;
        }
      }

      nav {
        display: flex;
        align-items: center;
        font-size: 82%;
        margin-right: 12px; // fake center

        a {
          max-width: 68px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        a span {

          color: var(--brandLight1);
        }
      }

      button:focus {
        outline: 0;
        color: white;
      }
    }

  }
}

html[data-theme="dark"] {
  .sticky {
    .headerLinks {
      box-shadow: none;
    }
  }
}



.searchWrapper {

  display: flex;
  justify-content: flex-end;
  position: relative;


}

.logoLink {
  color: var(--brandLight8);
  display: flex;
  align-items: center;

  .logo {
    display: block;
    position: relative;
  }

  .logoSmall {
    width: 36px;
    height: 36px;
  }

  .logoLarge {
    display: none;
  }
}


/* Applies to tablets (+768px) */
@media screen and (breakpoints.$mdUp) {
  .header {
    .headerLinks {
      .headerLinksInner {
        grid-template-columns: 6% 44% 50%;

        nav {

          display: flex;

          flex-grow: 1;
          font-size: 84%;

          a {
            line-height: 10px;
            max-width: none;
          }
        }
      }

    }
  }



}

@media screen and (min-width: 1040px) {
  .header {
    .headerLinks {
      .headerLinksInner {
        grid-template-columns: 22% 38% 40%;
      }
    }
  }

  .logoLink {
    .logoLarge {
      display: block;
      width: 168px;
      margin-left: various.$padding-base;
    }
  }
}

/* Applies to large screens (+1200px) */
@media screen and (breakpoints.$xlUp) {

  .hasStickyFilterBar {
    top: 0;
  }

  .header {
    .headerLinks {
      margin-top: various.$padding-base-2x;
      border-radius: 16px 16px 0 0;
      padding-left: 14px;
      padding-right: 14px;
      transition: all .1s linear;

      &.rounded {
        border-radius: 16px;
        box-shadow: 3px 3px 12px rgba(90, 115, 163, 0.4);
      }


      .headerLinksInner {

        grid-template-columns: 20% 32% 48%;

        margin-left: 0;
        margin-right: 0;

        nav {
          font-size: 0.9em;
        }

        .authAndMenu {

          span {
            margin-left: 0.15em;
          }

          button {
            margin-left: 0.25em;
            margin-right: 0.25em;
          }
        }
      }
    }
  }

  .sticky {
    .logoLarge {
      display: block;
      width: 168px;
    }
  }
}

html[data-theme="dark"] {
  .rounded {
    box-shadow: none !important;
  }
}

/* Applies to larger screens (+1200px) */
@media screen and (breakpoints.$xlUp) {
  .header {
    width: 1176px;
  }

  .logoLink {

    .logoLarge {
      position: relative;
      margin-left: various.$padding-base;
      width: 168px;
      display: block;
    }
  }
}

/* Applies to largest screens (+1400px) */
@media screen and (breakpoints.$xxlUp) {
  .header {

    width: 1378px;
  }
}