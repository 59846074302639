.topMessageWrapper {
  display: sticky;
}

.stickyTopMessage {
  display: flex;
  justify-content: center;
  padding: 12px;
  border-radius: var(--borderRadius);
  margin-bottom: 16px;
  font-weight: 500;
  position: relative;
}

.stickyTopMessage a {
  color: inherit;
}
