@use "breakpoints.scss";

.featureItem {
    --chevron-default: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23A3A3A3'%3E%3Cpath d='M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z'/%3E%3C/svg%3E");
    --chevron-green: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2300B780'%3E%3Cpath d='M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z'/%3E%3C/svg%3E");

    margin: 2px 0;
    display: flex;
    align-items: center;

    &:hover .featureLabel::before {
        border-color: var(--positiveGreener);
        background-color: var(--positiveGreenerLight);
        background-image: var(--chevron-green);
    }

    &.isSelected .featureLabel::before {
        background-image: var(--chevron-green) !important;
        border-color: var(--positiveGreener) !important;
        background-color: var(--positiveGreenerLight) !important;
    }
}

.featureLabel {
    font-weight: 500;
    padding: 1px 3px;
    font-size: 0.8em;
    border-radius: 2px;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media screen and (breakpoints.$smDown) {
        font-size: 0.8em;
        padding: 3px 6px;
    }
}

.isSelected .featureLabel {
    border-color: #d8e7f2;
    color: #00243f;
}

.voteMeta {
    margin-left: auto;
    display: flex;
    align-items: center;

    .meta {
        margin-left: 0.5em;
    }
}

.chevronCheckbox {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;

    &+.featureLabel::before {
        content: "";
        display: inline-block;
        margin-right: 0.75em;
        width: 16px;
        height: 16px;
        background-image: var(--chevron-default);
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid var(--gray400);
        border-radius: 2px;
        padding: 2px;
        background-color: var(--gray50);
        transition: all 0.2s ease;
    }
}