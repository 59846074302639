@use "breakpoints.scss";

.featurePoint {
    background-color: #00B780;
    border-radius: 10px;
    color: #00B780;
    margin-right: 4px;
    height: 10px;
    line-height: 8px;
    width: 10px;
    border: 2px solid #B4EFCA; //padding: 4px 0

    &.gray {
        background-color: var(--gray300);
        color: var(--gray300);
        border: 2px solid var(--gray150);

    }

    &.property {
        background-color: var(--popBrand);
        color: var(--brandLight3);
        border: 2px solid var(--brandLight3);
    }
}

.lang {
    margin-right: 4px;
}

.game {
    margin-right: 4px;
}

.listItem {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--brandLight10);
    padding: 4px 2px 4px 6px;
    border: 1px solid var(--brandLight5);
    border-radius: 6px;
    cursor: pointer;
    font-size: 90%;




    .title {
        line-height: 18px;


        .titleText {
            white-space: nowrap;
            flex-grow: 1;
            max-width: 30vw;
            overflow: hidden;

            &.hot {
                font-weight: 500;
            }

            .likes {
                font-size: 80%;
                padding-left: 4px;
                color: var(--metaLight)
            }
        }

        &.minor {
            font-weight: 400;

        }
    }


    .toggle {
        margin-right: 2px;
        margin-left: 6px;
        color: var(--gray300);
        display: flex;
        align-items: center;
    }



    .toggle-true {
        transform: rotate(180deg);
    }
}

.popoverContent {

    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1),
        0 2px 4px -2px rgba(0, 0, 0, .1);

    border: 1px solid var(--gray300);
    border-radius: var(--borderRadius);
    max-width: 170px;
    padding: 8px 8px 18px 12px;
    background-color: var(--mainBg);
    margin-top: 6px;
    background-color: var(--brandLight10);
    font-size: 0.8em;

}

.voteContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    padding-top: 8px;
}


/* Applies to medium screens (+768px) */
@media screen and (breakpoints.$mdUp) {

    .featurePoint {
        border-radius: 12px;
        height: 12px;
        line-height: 10px;
        width: 12px;
    }

    .listItem {
        padding: 9px 6px 9px 16px;
        font-size: 100%;



        .title {
            line-height: 20px;

            .titleText {
                max-width: unset;


            }
        }

        &.normal {
            padding: 7px 5px 7px 12px;
            font-size: 96%;

            .title {
                line-height: 18px;
            }
        }
    }

    .popoverContent {
        max-width: 282px;
        padding: 8px 12px 18px 12px;
    }
}