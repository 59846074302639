@use "breakpoints.scss";

.imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    font-weight: bold;
    font-size: 24px;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.portrait {
        top: 12px;
        border-radius: 10px;
        height: 170px;
    }
}

.portraitNew {
    border-style: solid;
    border-color: #000000;
    border-width: 3px;
    border-radius: 10px;
    height: 87%;
    width: 100%;
    object-fit: contain; // Add this to maintain aspect ratio


    @include breakpoints.mobile {
        height: 84%;
        width: 85%;
    }
}

.fill {
    background-size: cover;
    object-position: 0 0;
    position: absolute;
    width: 100%;

}