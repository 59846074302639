@import "shared/dropdownList.scss";

.list {
    cursor: default;
    background-color: var(--mainBg);

    .listItem {
        cursor: default;

        a {
            text-decoration: underline;
        }
    }
}