@use 'breakpoints.scss';
@use "various.scss";


.suggestFeature {
    font-size: 0.750em;
    cursor: pointer;
    color: var(--metaLight);


    span:hover {
        color: var(--meta);
    }

    &.alwaysShow {
        visibility: visible !important;
        opacity: 1 !important;
    }
}


.featureContainer {
    margin: 0 0 0 0;
    padding: 0 0 0 0;

}

.featureGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    font-size: 0.875em;
    margin-top: 8px;
}

.otherFeatures {
    margin-top: 14px;

}

/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {
    .featureGrid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 12px;
    }




    .featureGrid-large {
        grid-template-columns: repeat(4, 1fr);
    }

    .featureGrid-small {
        grid-template-columns: repeat(3, 1fr);
    }
}