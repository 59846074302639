.linksWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0.5rem 0;
    margin-left: 0.4rem;

    .linkItem {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        &:hover {
            opacity: 0.8;
        }
    }
}