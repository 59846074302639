.featureList {
  max-height: 200px;
  overflow: auto;
  overflow-x: hidden;
  padding-right: 5px;
}

/* Applies to small mobiles (-576px) */
@media screen and (max-width: 576px) {
  .featureList {
    max-height: 142px;
  }
}
