@use "various.scss";
@use "breakpoints.scss";

.newsItemWrapper {

    display: flex;
    margin: various.$padding-base-2x 0;
    border-radius: 0 6px 6px 0;
    flex-direction: column;


    .newsItemBox {
        border-radius: 6px 0 0 6px;
        background-color: var(--gray800);
        display: flex;
        align-items: center;
        padding: 4px 18px;
        color: var(--brandLight6);
        font-weight: bold;
    }

    &.about {
        background-color: var(--brandLight6);
    }

    &.subcategory {
        background-color: var(--brandLight4);
        border-radius: 6px;
    }



    .newsItemLink {

        padding: 0 8px 6px 12px;
        width: 100%;
        font-size: 90%;

        a {
            color: var(--mainFg);
            font-weight: 500;
        }

        p {
            color: var(--meta);
        }
    }

    .newsMeta {
        font-size: 0.75em;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        column-gap: 6px
    }
}


/* Applies to large screens (+992px) */
@media screen and (breakpoints.$mdUp) {
    .newsItemWrapper {
        flex-direction: row;
        max-height: 140px;
    }
}