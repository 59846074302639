.notificationBadge {
  position: absolute;
  top: -2px;
  left: 8px;
  color: white;
  font-size: 50%;
  background-color: red;
  padding: 2px;
  font-weight: bold;
  border-radius: 2px;
}
