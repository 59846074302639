@use "breakpoints.scss";

$landscapeWidth: 309px;
$screenshotHeight: 197px;

$portraitWidth: 94px;

$landscapeWidthLarge: 378px;
$screenshotHeightLarge: 231px;

$screenshotHeightSmaller: 64px;
$landscapeWidthSmaller: 95px;

.thumbnailWrapper {

    gap: 12px;

    .thumbnail {
        padding: 0;
        border: none;
        justify-content: center;
        display: flex;
        border-bottom: none;
        background-color: var(--gray50);

        &.noScreens {
            display: none;
        }

        &>div {
            flex-shrink: 0;
            align-self: center;
            filter: contrast(0.9);
            overflow: hidden;
            cursor: pointer;
            position: relative;
            border-radius: 15px 15px 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        &.portrait {
            background-color: var(--brandLight12);
            border-radius: 15px 15px 0 0;
        }

        &.landscape {

            &>div {

                //border: 1px solid var(--gray200);
                width: 100%;

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
                    z-index: 1;
                }
            }
        }

        &.smaller {
            display: none;
        }

        &.small.portrait {
            gap: 5%;

            &>div {

                width: $portraitWidth;
                height: $screenshotHeight;
            }
        }

        &.large.portrait>div {
            width: $portraitWidth;
            height: $screenshotHeightLarge;
        }

        &.small.landscape>div {
            height: $screenshotHeight;
        }

        &.large.landscape>div {
            height: $screenshotHeightLarge;
        }

        &.compactBox {
            &>div {
                border: none;
                border-radius: 15px 15px 0 0 !important;
                box-shadow: none;

            }
        }
    }

}



.expandImage {
    display: none;

}


/* Applies to medium screens (+768px) */
@media screen and (breakpoints.$mdUp) {

    .thumbnailWrapper {

        display: flex;
        justify-content: flex-start;

        .thumbnail {
            //border-radius: 0 15px 15px 0 !important;
            border-radius: var(--borderRadius);
            box-shadow: none;
            position: relative;


            &.portrait {
                background-color: var(--brandLight12);
                border-radius: 15px;
            }

            &>div {
                box-shadow: none;
                margin-right: 0;
                overflow: hidden;
                break-inside: never;
                border-radius: var(--borderRadius);
                line-height: 0;
                overflow: hidden;
                max-height: 280px;

                .expandImage {
                    opacity: 0;
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    transition: opacity var(--minorTransition) linear;
                    cursor: pointer;
                    background-color: rgba(255, 255, 255, 0.8);
                    padding: 8px;
                    border-radius: var(--borderRadius);
                    line-height: 0;
                }

                &:hover {
                    .expandImage {
                        opacity: 1;
                        display: block;
                    }
                }
            }

            &.large {
                &.noScreens {
                    width: $landscapeWidthLarge;
                }

                &>div {
                    //border-radius: var(--borderRadius);
                    border-radius: 0 15px 15px 0 !important;
                }

                &.portrait {
                    width: $landscapeWidthLarge;

                    &>div {
                        width: 98px;
                        height: $screenshotHeightLarge;
                    }
                }

                &.landscape>div {
                    width: $landscapeWidthLarge;
                    height: $screenshotHeightLarge;

                    &::after {
                        background: none;
                    }
                }
            }

            &.small {
                &.noScreens {
                    width: $landscapeWidth;

                }

                &.portrait {
                    width: $landscapeWidth;


                    &>div {
                        width: 82px;
                        height: $screenshotHeight;
                    }
                }

                &.landscape>div {
                    width: $landscapeWidth;
                    height: $screenshotHeight;

                    &::after {
                        background: none;
                    }
                }
            }

            &.smaller {

                display: flex;
                border-radius: 6px !important;
                gap: 12px;
                margin-top: 12px;

                &.landscape>div {
                    width: $landscapeWidthSmaller;
                    height: $screenshotHeightSmaller;

                    &::after {
                        background: none;
                    }

                    .expandImage {

                        bottom: 4px;
                        right: 4px;
                        padding: 4px;

                        svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                &>div {
                    height: $screenshotHeightSmaller;
                }

                &.noScreens {
                    min-height: $screenshotHeightSmaller;
                    width: $landscapeWidthSmaller;
                }
            }

            &.expandedBox {
                border-radius: var(--borderRadius) !important;

                &>div {
                    border-radius: var(--borderRadius) !important;
                    box-shadow: none;

                }
            }

            &>div {
                border-radius: var(--borderRadius) !important;
                box-shadow: none;

            }

            &.compactBox {
                border-radius: var(--borderRadius) !important;

                &>div {
                    border-radius: var(--borderRadius) !important;
                }
            }

        }
    }
}

/* Applies to very large screens (+992px) */
@media screen and (breakpoints.$lgUp) {
    .thumbnailWrapper {
        .thumbnail {
            border-radius: var(--borderRadius) !important;

            &>div {
                border-radius: var(--borderRadius) !important;
                box-shadow: none;

            }

            &.compactBox {
                border-radius: 0 15px 15px 0 !important;

                &>div {
                    border-radius: 0 15px 15px 0 !important;
                }
            }
        }
    }
}