@use "breakpoints.scss";

.likeWrapper {
    container: likeWrapper / inline-size;
    //min-width: 200px;
    display: flex;
    align-items: center;

    @include breakpoints.mobile {
        font-size: 14px;
    }

    .heart {
        color: var(--brandLight3);
        cursor: pointer;
        display: flex;
        margin-right: 12px;

        &:hover {
            svg {
                color: #eba5a3;
            }
        }

        span:first-child {
            height: 32px;
            padding-top: 2px;
            padding-bottom: 2px;
        }

        @include breakpoints.mobile {
            span:first-child {
                height: 30px;
                padding-top: 2px;
                padding-bottom: 2px;
            }
        }

    }

    .liked {
        svg {
            color: #eba5a3;
        }

        &:hover {
            svg {
                color: var(--danger);
            }
        }
    }



}

@container likeWrapper (inline-size < 220px) {

    /* Styles for horizontal article */
    .likeWrapper>div>div>span {
        display: none;
    }

    .likeWrapper>div>div>div {
        display: none;
    }

    .heart {
        margin-right: 0 !important;
    }
}

/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {
    .likeWrapper {
        .heart {
            align-items: center;
        }
    }
}