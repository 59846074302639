@use "breakpoints.scss";
@use "various.scss";

.h1 {
  font-size: 1.8em;

  &.smaller {
    font-size: 1.5em;
  }

  &.smallest {
    font-size: 1.2em;
  }
}

.h2 {
  font-size: 1.6em;
}

.h3 {
  font-size: 1.35em;

  &.smaller {
    font-size: 1.25em;
  }
}

.h1 a,
.h2 a {
  color: var(--linkColorHeader);
}

.h4 {
  font-size: 1.2em;


}

.h5 {
  font-size: 18px;

}

.kicker {
  display: block;
  margin-bottom: -10px;
}

.centerKickerContainer {
  display: flex;
  justify-content: center;

}

.centerKicker {
  display: block;
  text-align: center;
  background-color: var(--brandLight3);
  padding: 2px;
  border-radius: var(--borderRadius);
  width: 80px;
  font-size: 0.85em;
  color: var(--popBrand);
}

.center {
  text-align: center;
  margin-bottom: various.$padding-base-4x;
}

.metaHeading {
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.14em;
  font-size: 0.75em;
  text-transform: uppercase;

  &.h2 {
    font-weight: 500;
  }

  &.h3 {
    font-weight: 400;
    font-size: 0.7em;
  }

  &.h4 {
    font-weight: 400;
    font-size: 0.7em;
  }
}

/* Applies to medium screens (+768px) */
@media screen and (breakpoints.$mdUp) {
  .h1 {
    font-size: 2.5em;

    &.smaller {
      font-size: 2.2em;
    }

    &.smallest {
      font-size: 1.9em;
    }
  }

  .h2 {
    font-size: 1.875em;
  }

  .h3 {
    font-size: 1.4em;

    &.smaller {
      font-size: 1.25em;
    }
  }

  .h4 {
    font-size: 1.25em;

    &.smaller {
      font-size: 1em;
    }
  }

  .h5 {
    font-size: 1em;

    &.smaller {
      font-size: 0.9em;
    }
  }



  .h1,
  .h2,
  .h3,
  .h4 {
    overflow-wrap: normal;
  }

  .metaHeading {

    font-weight: 400;
    line-height: 18.2px;
    letter-spacing: 0.14em;
    font-size: 0.8em;
    text-transform: uppercase;

    &.h2 {
      font-weight: 500;
    }

    &.h3 {
      font-weight: 400;
      font-size: 0.8em;
    }

    &.h4 {
      font-weight: 400;
      font-size: 0.75em;
    }
  }
}

.separator:after {
  content: "";
  display: inline-block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  width: 120px;
  height: 4px;
  background: var(--mainFg);
  border-radius: 2px;
}

.buttonWrapper {
  position: relative;
  top: -0.175em;
  margin-left: 0.75rem;
  font-size: 0.5em;
}