.profilePictures {
    display: flex;
    height: 20px;
    align-items: center;
    margin-left: 10px;

    span {
        overflow: hidden;
        position: relative;

        div div,
        img {
            border: 1px solid var(--mainBg);
        }
    }

    &.large {
        height: 28px;

        span.imgSpan {
            margin-left: -10px;
            height: 30px;

        }

        // .metaText {
        //     margin-left: 28px;
        // }
    }

    &.small {

        .imgSpan {
            margin-left: -8px;
        }

        span {

            height: 20px;
        }
    }

    span:nth-child(1) {
        z-index: 1;

    }

    span:nth-child(2) {
        z-index: 2;
    }

    span:nth-child(3) {
        z-index: 3;
    }

    span:nth-child(4) {
        z-index: 4;
    }

    span:nth-child(5) {
        z-index: 5;
    }

    .metaText {
        margin-left: 6px;
        color: var(--metaLight);

    }
}