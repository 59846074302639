@use "various.scss";

.showOnHover {
    @include various.hidden-opacity;
    padding-left: various.$padding-base-2x;


    div {
        display: inline-flex;
        align-items: center;
        font-size: 90%;
    }
}

ul#detailsList:has(> li > input#showCheckbox:checked)>li {
    display: block;
}

.heading:hover {
    .showOnHover {
        @include various.show-opacity;
    }
}

.mark {
    box-shadow: 0 0 0 2px var(--mainBg);
    background-color: var(--gray300);
    line-height: 1.4;
    display: table;
    padding: 2px;
}

.highlightedHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: various.$padding-base;
}

/* four divs after #hello will be red */
// .heading:not(.mark) {
//     ~p {
//         background: white
//     }
// }

/* reset red background to default, #hello2 and all next will be white */

.mark+p,
.mark+p+p,
.mark+p+p+p,
.mark+p+p+p+p {
    box-shadow: 0 0 0 2px var(--mainBg);
    background-color: var(--gray300);
    line-height: 1.4;
    padding: 0 4px;

}